<template>
  <div class="positionDetail content_area">
    <div class="clearfix">
      <!-- 职位投递 -->
      <div class="jobDelivery clearfix" v-loading="loading">
        <div class="fl">
          <div class="title">
            {{ positionInfo.name | textFormat }}
            <span v-if="positionInfo.salary_lowest != 0"
              >{{ positionInfo.salary_lowest | textFormat }}K —
              {{ positionInfo.salary_highest | textFormat }}K</span
            >
            <span v-else style="font-size: 17px">薪资面议</span>
          </div>
          <div class="desc clearfix">
            <div class="fl">
              <span
                >{{ positionInfo.region | textFormat }}
                |
              </span>
              <span
                >{{ positionInfo.experience | experienceFormat(LOCALE) }}
              </span>
              <span
                >|
                {{
                  positionInfo.level_require | levelRequireFormat("zh")
                }}</span
              >
            </div>
            <div class="fl like clearfix">
              <!-- <div class="fl">
                <el-image
                  class="img cursor"
                  :src="currentLike ? like : nolike"
                  @click="likeChange"
                ></el-image>
              </div>
              <div class="fl">收藏</div> -->
            </div>
          </div>
        </div>
        <div
          class="fr"
          style="margin-top: 25px"
          v-show="positionInfo.status != 4"
        >
          <!-- <submitResume :position_id="position_id">
            <el-button plain size="small" slot="btn" style="margin-right: 16px"
              >立即投递</el-button
            >
          </submitResume>
          预约组件投递
          <orderTalk
            :in_companyId="positionInfo.company_id"
            :in_userId="positionInfo.person_in_charge"
            :position_id="position_id"
            ><el-button type="primary" size="small" slot="btn"
              >预约面试</el-button
            ></orderTalk
          > -->
          <!-- <cencenlInterview
            :chat_type="'1'"
            :position_id="'5'"
            :one2oneChatId="'32'"
          >
            <el-button slot="btn" size="small" :loading="btnLoading"
              >取消面试</el-button
            >
          </cencenlInterview> -->
          <!-- <immediatelyTalk :channel_id="'7423743829'" :status="'3'"></immediatelyTalk> -->
        </div>
      </div>
      <div class="content fl myC_width">
        <!-- 岗位描述: -->
        <div class="jobDescribe" v-loading="loading">
          <div class="title">岗位描述</div>
          <div v-html="positionInfo.desc" class="pre-text"></div>
          <!-- <pre>{{positionInfo.desc}}</pre> -->
          <div v-if="!loading && !positionInfo.desc" class="tc">暂无描述</div>
        </div>
        <!-- 工作地址 -->
        <div class="jobAdress" v-loading="loading">
          <div class="title">工作地址</div>
          <div>{{ positionInfo.address }}</div>
          <div v-if="!loading && !positionInfo.address" class="tc">
            暂无数据
          </div>
          <div class="companyVideo" v-if="positionInfo.aliyun_id">
            <div class="mask">
              <i
                class="el-icon-video-play iconPlay cursor"
                @click="playVideo(positionInfo.aliyun_id)"
              ></i>
            </div>
            <el-image
              v-show="positionInfo.cover_url"
              :src="positionInfo.cover_url"
              style="width: 300px; height: 200px"
              class="img"
            ></el-image>
          </div>
        </div>
        <!-- 联系方式 -->
        <div class="connect" v-loading="loading" v-if="positionInfo.contact">
          <div class="title">联系方式</div>
          <div class="item">
            <span>姓名</span>
            <span class="textOverflow">{{
              JSON.parse(positionInfo.contact).name | textFormat
            }}</span>
          </div>
          <div class="item">
            <span>邮箱</span>
            <span class="textOverflow">{{
              JSON.parse(positionInfo.contact).email | textFormat
            }}</span>
          </div>
          <div class="item">
            <span>电话</span>
            <span class="textOverflow">{{
              JSON.parse(positionInfo.contact).mobile | textFormat
            }}</span>
          </div>
        </div>
      </div>
      <div class="fr history">
        <companyDetail :info="positionInfo" />
        <!-- <div class="jobPost" v-show="positionList.length > 0">
          <jobPost :positionList="positionList" />
        </div> -->
      </div>
    </div>
    <div class="otherJob" v-loading="sameLoading">
      <div class="title">相似职位</div>
      <div class="clearfix">
        <div v-for="(it, index) in sameList" class="otherItem fl" :key="index">
          <otherJobItem :sameItem="it"></otherJobItem>
        </div>
      </div>
      <noDataImg v-if="!sameLoading && sameList.length == 0"></noDataImg>
    </div>
    <publicNav />
    <videoPlay ref="videoPlay" class="itemBox"></videoPlay>
  </div>
</template>
<script>
import otherJobItem from "~trf/components/position/otherJobItem";
import jobPost from "~trf/components/position/jobPost";
import companyDetail from "~trf/components/position/companyDetail";
import publicNav from "~trf/components/index/publicNav";
import orderTalk from "~trf/components/button/orderTalk";
import submitResume from "~trf/components/button/submitResume";
import videoPlay from "@/baseComponents/videoPlay";

export default {
  metaInfo: {
    title: "职位详情",
  },
  components: {
    otherJobItem,
    jobPost,
    companyDetail,
    publicNav,
    submitResume,
    orderTalk,
    videoPlay,
  },
  data() {
    return {
      currentLike: false,
      orderVisible: false,
      loading: false,
      sameLoading: false,
      btnLoading: false,
      position_id: null,
      positionInfo: {},
      positionList: [],
      sameList: [],
      orderShow: 1,
      nolike:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/like.png",
      like: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/like1.png",
    };
  },
  watch: {
    //   // '$route' : 'getList'
    $route(to, from) {
      this.position_id = this._decode(this.$route.query.parameter).id;
      this.getList();
    },
  },
  // routerBeforeEnter(){
  //   this.position_id = this._decode(this.$route.query.parameter).id;
  //   this.getList()
  // },
  methods: {
    orderVisibleShow(val) {
      if (val) {
        this.orderVisible = true;
      } else {
        this.orderVisible = false;
      }
    },
    async getList() {
      this.loading = true;
      this.sameLoading = true;
      let params = {
        position_id: this.position_id,
        source: this.PJSource,
        user_id: this.USER_INFO.id,
      };
      let result = await Promise.all([
        this.$store.dispatch("API_position/jobPositionDetail", params),
        this.$store.dispatch("API_booth/viewPosition", {
          start: 0,
          limit: 5,
          nopage: 0,
          source: this.PJSource,
          user_id: this.USER_INFO.id,
        }),
        this.$store.dispatch("API_position/jobRecommendSame", {
          position_id: this.position_id,
          source: this.PJSource,
          limit: 6,
        }),
        this.$store.dispatch("API_position/historyAdd", {
          position_id: this.position_id,
          source: this.PJSource,
          user_id: this.USER_INFO.id,
        }),
      ]);
      if (result[0] && result[0].success) {
        this.loading = false;
        this.positionInfo = result[0].data;
        this.currentLike = this.positionInfo.position_collection_id
          ? true
          : false;
      } else {
        this.loading = false;
      }
      if (result[1] && result[1].success) {
        this.loading = false;
        this.positionList = result[1].data;
      }
      if (result[2] && result[2].success) {
        this.sameLoading = false;
        this.sameList = result[2].data;
      }
    },
    async getStatus() {
      let result = await this.$store.dispatch(
        "API_position/getPositionStatus",
        {
          position_id: this.position_id,
          source: this.PJSource,
          user_id: this.USER_INFO.id,
        }
      );
      if (result.success) {
        let status = result.data && result.data.status;
        if (JSON.stringify(result.data) != "{}") {
          if (status === 0 || status === 1) {
            this.orderShow = 2;
          } else {
            this.orderShow = 3;
          }
        }
      }
    },
    async likeChange() {
      let params = {
        position_id: this.position_id,
        user_id: this.USER_INFO.id,
        source: this.PJSource,
      };
      if (this.currentLike) {
        params.deleted = 1;
        params.job_position_collection_id =
          this.positionInfo.position_collection_id;
      }
      let res = await this.$store.dispatch(
        "API_position/JobCollection",
        params
      );
      if (res.success) {
        this.currentLike = !this.currentLike;
      }
    },
    playVideo(id) {
      this.$refs.videoPlay.startPlay(id);
    },
    btnLoadingChange(val) {
      this.btnLoading = val;
    },
  },
  mounted() {
    this.INFO_MATION();
    this.position_id = this._decode(this.$route.query.parameter).id;
    this.getList();
    // this.getStatus();
  },
};
</script>
<style lang="less" scoped>
.positionDetail {
  padding: 24px 0;
  .jobDelivery {
    height: 120px;
    background: #ffffff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    padding: 20px 30px;
    .title {
      font-size: 30px;
      font-weight: 500;
      margin-bottom: 6px;
      span {
        color: #ff2b0d;
        margin-left: 10px;
      }
    }
    .desc {
      .like {
        margin-left: 30px;
        .img {
          width: 14px;
          height: 12px;
          margin: 4px 5px;
        }
      }
    }
  }
  .content {
    width: 888px;
  }
  .myC_width {
    width: 888px !important;
  }
  .jobDescribe {
    padding: 20px 30px;
    min-height: 200px;
    background: #ffffff;
    margin: 16px 0;
    font-size: 14px;
    .title {
      font-size: 18px;
      margin-bottom: 24px;
      font-weight: 500;
    }
  }
  .history {
    width: 300px;
  }
  .connect {
    padding: 20px 30px;
    margin-bottom: 24px;
    background: #ffffff;
    font-size: 14px;
    .title {
      font-size: 18px;
      margin-bottom: 24px;
      font-weight: 500;
    }
    .item {
      display: flex;
      align-items: center;
      span {
        line-height: 40px;
        font-size: 16px;
        box-sizing: border-box;
        color: #333;
      }
      span:first-child {
        padding-right: 15px;
      }
      span:nth-child(2) {
        width: 300px;
      }
    }
  }
  .jobAdress {
    padding: 20px 30px;
    margin-bottom: 24px;
    background: #ffffff;
    min-height: 300px;
    font-size: 14px;
    .title {
      font-size: 18px;
      margin-bottom: 24px;
      font-weight: 500;
    }

    .companyVideo {
      width: 300px;
      height: 200px;
      border: 1px solid #ececec;
      margin-top: 30px;
      position: relative;
      .mask {
        position: absolute;
        top: 0;
        width: 300px;
        height: 200px;
        z-index: 2;
        background: rgba(0, 0, 0, 0.3);
        &:hover {
          background: rgba(0, 0, 0, 0.5);
          i {
            color: #ececec;
          }
        }
      }
      .iconPlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        font-size: 45px;
        color: #dbd8d8;
      }
    }
  }
  .otherJob {
    min-height: 300px;
    position: relative;
    .title {
      font-size: 18px;
      margin-bottom: 16px;
      font-weight: 500;
    }
    .otherItem {
      width: 391px;
      height: 200px;
      border: 1px solid #e9e9e9;
      margin-right: 12px;
      background: #fff;
      margin-bottom: 16px;
      padding: 32px 16px 26px;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  .jobPost {
    margin-top: 16px;
  }
}
.pre-text {
  white-space: pre-wrap;
  word-wrap: break-word;
}
/deep/.el-dialog__headerbtn {
  top: 10px;
}
</style>
